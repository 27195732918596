import { TopBarModel } from "@new-wars/common/src/TopBar/TopBarModel";
import { COMPANY_BUTTON, COMPANY_NAME, HEADER_NAV_ITEMS, Model } from "@new-wars/core";
import { makeObservable } from "mobx";
import { HomeViewApiData } from "./HomeViewModelBuilder";

export class HomeViewModel extends Model {
  headerModel = new TopBarModel(COMPANY_NAME, COMPANY_BUTTON, HEADER_NAV_ITEMS);

  constructor(apiData: HomeViewApiData) {
    super(apiData);
    // this.data = apiData.data;
    // this.cats = apiData.cats;
    // this.bob = apiData.bob;

    makeObservable(this, {
      //   data: observable,
      //   cats: observable,
      //   bob: observable
    });
  }
}
