import { IModelBuilder } from "@new-wars/core/src/mvvm/ModelBuilder";
import { HomeViewModel } from "./HomeViewModel";

export type HomeViewApiData = {};

export class HomeViewModelBuilder implements IModelBuilder<HomeViewModel> {
  build(apiData: HomeViewApiData): HomeViewModel {
    return new HomeViewModel(apiData);
  }

  async loadApiData() {
    const apiData = {};

    return apiData;
  }
}
