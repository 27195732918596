import { Box } from "@chakra-ui/react";
import { withModel } from "@new-wars/common";
import { observer } from "mobx-react";
import { GetServerSideProps, NextPage } from "next";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import Layout from "../../components/Layout/Layout";
import { HomeViewModel } from "./HomeViewModel";
import { HomeViewModelBuilder } from "./HomeViewModelBuilder";

type HomeViewProps = {
  model: HomeViewModel;
};

const HomeView: NextPage<HomeViewProps> = observer(({ model }) => {
  const { t } = useTranslation("home");
  return (
    <Box>
      <Head>
        <title>Strona Główna</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout>
        <Box>{t("slogan")}</Box>
      </Layout>
    </Box>
  );
});

export const getServerSideHomeViewProps: GetServerSideProps = async context => {
  // Fetch data from external API
  // const apiData = await new HomeViewModelBuilder().loadApiData();
  // Pass data to the page via props
  const apiData = { test: {} };
  return { props: { apiData } };
};

export default withModel<HomeViewModel, HomeViewModelBuilder>(HomeView, HomeViewModelBuilder);
